import { turnstileChallenge } from "~/javascripts/turnstile";

const loginEmailLabel = document.getElementById('login-email-label')
const loginTypeBorder = document.getElementById('login-pink-border')

var loginType = 'email'

function clearInputFeedback() {
  const inputFeedback = document.querySelectorAll('.input-feedback')
  for (let i=0; i < inputFeedback.length; i++) {
    inputFeedback[i].innerHTML = "";
  }
}

function showErrorMessage(error) {
  const error_key = Object.keys(error)[0]
  const error_field = document.querySelector(`#${error_key}`);
  error_field.innerHTML = error[error_key];
}

const loginUser = async () => {
  try {
    const turnstileToken = await turnstileChallenge('login')
    return sendLoginForm(turnstileToken)
  } catch (error) {
    showErrorMessage({
      login_error: error
    });
  
    loginButton.disabled = false
  }
}

const sendLoginForm = async (turnstileToken) => {
  const csrfToken = document.querySelector("[name='csrf-token']").content
  const email = document.querySelector('#email-field').value
  const password = document.querySelector('#password-field').value
  
  const form = new FormData()
  form.set('turnstile_token', turnstileToken)
  form.set('authenticity_token', csrfToken)
  form.set('login_type', loginType)
  form.set('password', password)

  if (loginType == 'email') {
    form.set('email', email)
  } else {
    form.set('country_code', country_code)
    form.set('phone', phone)
  };

  return await fetch('/login', {
    method: 'POST',
    body: form
  })
}

const loginForm = document.querySelector('#login-form')
const loginButton = document.querySelector('#login-button')
loginForm.addEventListener('submit', async (e) => {
  e.preventDefault()
  clearInputFeedback();
  loginButton.disabled = true
  const res = await loginUser()
  const { success, errors } = await res.json()

  if (success) {
    // Redirect to success url
    window.location.assign('/')
  } else {
    showErrorMessage(errors);

    loginButton.disabled = false
  }
})
